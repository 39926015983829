import axios from "axios";
import { TOKEN } from "@/utils/constants";

const token = localStorage.getItem(TOKEN);
const header = {
  Authorization: `Bearer ${token}`,
};

const baseURL = process.env.VUE_APP_BASE_URL;

export function login(payload) {
  return axios.post(`${baseURL}/staff/login`, { ...payload });
}

export function changePassword(payload) {
  return axios.post(
    `${baseURL}/staff/changepassword`,
    { ...payload },
    {
      headers: header,
    }
  );
}
