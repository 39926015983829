<template>
  <div class="flex justify-center items-center bg-white py-10">
    <div class="text-center">
      <Logo class="mb-5 inline-block text-black" />
      <h1 class="font-semibold text-2xl">Welcome</h1>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="changeUserPassword" class="text-left mt-10">
          <div class="mb-5">
            <label for="username" class="block mb-2">Old Password</label>
            <ValidationProvider
              name="Old password"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                id="username"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-72"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Old password"
                v-model="auth.old_password"
              /><br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="border border-b border-gray-500 mb-5" />
          <ValidationObserver v-slot="{ err }">
            <div class="mb-5">
              <label for="password" class="block mb-2">New Password</label>
              <ValidationProvider
                name="Password"
                rules="required|password:@confirm"
                v-slot="{ errors }"
              >
                <div
                  class="bg-gray-100 px-3 rounded-md w-72 flex justify-between items-center"
                  :class="errors[0] ? 'border border-red-400' : 'border-none'"
                >
                  <input
                    id="password"
                    :type="showPW ? 'text' : 'password'"
                    class="py-2 bg-transparent w-full border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
                    placeholder="Password"
                    v-model="auth.password"
                  />
                  <div @click="showPW = !showPW">
                    <EyeCloseIcon
                      class="w-5 opacity-40 cursor-pointer"
                      v-if="showPW"
                    />
                    <EyeIcon class="w-5 opacity-40 cursor-pointer" v-else />
                  </div>
                </div>
                <span class="block text-red-400 mt-3" v-if="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="mb-5">
              <label for="password2" class="block mb-2"
                >Confirm New Password</label
              >
              <ValidationProvider name="confirm" rules="required">
                <div
                  class="bg-gray-100 px-3 rounded-md w-72 flex justify-between items-center"
                  :class="err ? 'border-red-400' : 'border-none'"
                >
                  <input
                    id="password2"
                    :type="showPW2 ? 'text' : 'password'"
                    class="py-2 bg-transparent w-full border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
                    placeholder="Password"
                    v-model="auth.password2"
                  />
                  <div @click="showPW2 = !showPW2">
                    <EyeCloseIcon
                      class="w-5 opacity-40 cursor-pointer"
                      v-if="showPW2"
                    />
                    <EyeIcon class="w-5 opacity-40 cursor-pointer" v-else />
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </ValidationObserver>
          <button
            class="w-full text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :class="invalid ? 'bg-gray-300' : 'bg-primary'"
            :disabled="invalid || submitting"
          >
            {{ submitting ? "Please wait..." : "Create Account" }}
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { changePassword } from "@/services/auth";

export default {
  name: "LoginPage",
  components: {
    Logo: () => import("@/assets/imgs/Logo.vue"),
    EyeIcon: () => import("@/assets/icons/EyeIcon.vue"),
    EyeCloseIcon: () => import("@/assets/icons/EyeCloseIcon.vue"),
  },
  data() {
    return {
      auth: {
        old_password: "",
        password: "",
        password2: "",
      },
      showPW: false,
      showPW2: false,
      submitting: false,
    };
  },
  methods: {
    async changeUserPassword() {
      this.submitting = true;
      try {
        await changePassword({
          new_password: this.auth.password,
          old_password: this.auth.old_password,
        });
        this.$toast.success("Password changed");
        this.$router.push("/app/dashboard");
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
        this.submitting = false;
      }
    },
  },
};
</script>
